@import url('https://fonts.googleapis.com/css?family=Shrikhand&display=swap');

.App {
  text-align: center;
  align-items: center;
  align-content: center
}
 
.LogoText {
  font-family: 'Shrikhand', cursive;
  font-size: 70px
}

.PadingTop {
  padding-top: 100px
}


.ImageApp {
  width: 300px
}

@media only screen and (max-width: 600px) {
  .PadingTop {
    padding-top: 50px
  }
  .ImageApp {
    width: 150px
  }
}

.ByText {
  size: 30px;
  color:#282c34
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
